import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { toDate, endOfDay } from '@/utils/date'

export default {
  async selectPresencia (Vue, filter, search, sorter, page, idempleado) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idempleado', idempleado)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('observaciones_entrada', search)
        .addILike('observaciones_salida', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    const resp = await Vue.$api.call('presencia.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectPresenciaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idpresencia', pks)
    const resp = await Vue.$api.call('presencia.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
